<template>
  <el-tooltip
    :open-delay="700"
    effect="dark"
    :content="$t('no_data')"
    placement="bottom"
    :offset="2"
    :visible-arrow="false"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12C0 5.3832 5.38321 0 12 0C18.6168 0 24.0001 5.3832 24.0001 12C24.0001 18.6168 18.6168 24 12 24C5.38321 24 0 18.6168 0 12ZM9.78111 8.86362H7.68158C7.63065 5.83796 9.95461 4.79999 12.0653 4.79999C14.289 4.79999 16.3201 5.97111 16.3201 8.39517C16.3201 10.1262 15.3093 10.9508 14.3399 11.6936C13.1668 12.5904 12.7322 12.9257 12.7322 14.0775V14.647H10.6518L10.6407 13.9043C10.5802 12.4171 11.4286 11.5011 12.4998 10.7166C13.4406 10.0043 14.0359 9.53582 14.0359 8.5171C14.0359 7.19357 13.0363 6.6433 11.9459 6.6433C10.5101 6.6433 9.78111 7.61068 9.78111 8.86362ZM11.774 19.2C10.8428 19.2 10.1663 18.5679 10.1663 17.7128C10.1663 16.8273 10.8428 16.2048 11.7724 16.2048C12.7418 16.2048 13.4103 16.8273 13.4103 17.7128C13.4103 18.5679 12.7434 19.2 11.774 19.2Z"
        fill="#828D9B"
      />
    </svg>
  </el-tooltip>
</template>

<script>
export default {
  name: 'QuestionIcon'
}
</script>
